import styled from 'styled-components';
import { BUTTON_COLORS } from '@utils/constants';
import { Button } from '@components/Button';


export const GlobalLayout = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    width: 322px;
    height: 176px;
    padding: 25px 44px;
    flex-direction: column;
    align-items: flex-start;
    gap: 11px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid #C4C3C2;
    background: #FFF;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.13);
    `;

export const TitleContainer = styled.div`
    display: flex;
    height: 40px;
    flex-direction: column;
    justify-content: center;
    align-self: stretch;
    color: #3D3D3D;
    font-family: Nunito;
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    `;

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 8px;
    align-self: stretch;
    flex: 1;
    color: #000;
    font-family: Nunito;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

export const ButtonContainer = styled.div`
  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    flex-shrink: 0;
    width: 100%;
`;

export const CustomButton = styled(Button)`
        background-color: rgba(196, 195, 194, 1);
        &:hover {
            background-color: ${BUTTON_COLORS.default};
        }
`;

export const Previous = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
    font-family: 'Nunito';
  font-size: 15px;
    font-style: normal;
  font-weight: 800;
    line-height: normal;
  text-decoration: underline;
    text-decoration-style: solid;
`;