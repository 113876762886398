import { COLORS } from 'src/utils/constants';
import styled, { css } from 'styled-components';

export const MessageListContainer = styled.div`
  overflow-y: auto;
  flex: 1;
  padding-bottom: 80px;
`;

export const ChatBubbleRow = styled.div<{ isUser?: boolean }>`
  display: flex;
  padding: 15px 20px;
  align-items: flex-end;
  flex-direction: ${({ isUser }) => (isUser ? 'row-reverse' : 'row')};
  gap: 10px;
`;

export const ChatBubble = styled.div<{ isUser?: boolean; userDisabled?: boolean }>`
  border-radius: ${({ isUser }) => (isUser ? '8px 8px 0 8px' : '8px 8px 8px 0')};
  font-size: 12px;
  font-weight: 600;
  padding: 15px;
  background-color: ${({ isUser }) => (isUser ? 'rgba(243, 243, 243, 0.76)' : '#f2f2f2')};
  color: ${({ userDisabled }) => (userDisabled ? 'rgba(61, 61, 61, 1)' : 'rgba(61, 61, 61, 1)')};
`;

export const ChatContainer = styled.div`
  position: relative;
    display: flex;
  flex-direction: column;
  height: 100%;
`;

export const TimeContainer = styled.div<{ isUser?: boolean }>`
  display: flex;
  justify-content: flex-end;
  flex-direction: ${({ isUser }) => (isUser ? 'row' : 'row-reverse')};
`;

export const DateTimeContainer = styled.h6`
  opacity: 0.7;
  margin: 5px 0 0 0;
`;

export const TextContainer = styled.div<{ isUser?: boolean; userDisabled?: boolean }>`
  color: ${({ isUser, userDisabled }) => (isUser ? 'rgba(61, 61, 61, 1)' : userDisabled ? COLORS.Grey : '#2D313F')};
  word-wrap: break-word;

  span {
    &.class-at {
      border-radius: 9.5px;
      background: #f3e9ff;
      color: #a764ff;
      font-family: Nunito;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
        padding: 0px 5px;
    }
    &.class-at-activeUser {
      border-radius: 9.5px;
      background: rgba(58, 163, 204, 0.19);
      color: #3aa3cc;
      font-family: Nunito;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
        padding: 0px 5px;
    }
  }
`;

export const MessageFooter = styled.form`
  position: fixed;
  bottom: 0;
  width: 62%;
  background-color: #ffffff;
  padding: 10px;
  min-height: 85px;
`;

export const ChatBubbleContainer = styled.div`
  position: relative;
  width: 50%;
`;

export const EmptyChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 94%;
  row-gap: 20px;
  border: 1px dashed ${COLORS.MediumGrey};

  span {
    font-size: 1.3rem;
    width: 500px;
    max-width: 90%;
    text-align: center;

    b {
      color: ${COLORS.NiceBlue};
    }
  }

  svg {
    color: ${COLORS.MediumGrey};
    width: 60px;
    height: 60px;
    animation: swing 0.8s ease 0.3s;
  }
`;

export const Delete = styled.div<{ isUser?: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  border-bottom-left-radius: 5px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;

  svg {
    opacity: ${({ isUser }) => (isUser ? '0.9' : '0.7')};
    color: ${({ isUser }) => (isUser ? 'rgba(61, 61, 61, 1)' : '#2D313F')};
    width: 20px;
    height: 20px;
    pointer-events: none;
  }
`;

export const InputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 65px;
`;
export const CustomDivContainer = styled.div`
    display: flex;
    min-height: 40px;
    flex: 1;
    border: 1px solid rgba(217, 217, 217, 1);
    border-radius: 30px;
    padding: 10px 110px 10px 40px;
    overflow: hidden;
    flex-wrap: wrap;
    align-items: center;
`;
export const CustomDiv = styled.div`
    display: flex;
  font-family: 'Nunito';
  font-size: 0;
    height: auto;
    width: 100%;
  font-weight: 600;
  text-align: left;
  line-height: 1.5;
    overflow: hidden;
    white-space: nowrap;
    flex-wrap: wrap;
  
    
  &:empty:not(:focus):before {
    font-size: 1.4rem;
    content: attr(data-text);
    pointer-events: none;
  }

  &:focus {
    font-size: 1.4rem;
    outline: 0px solid transparent;
  } 
    br {
        line-height: 15px;
        margin-left: 4px;
    }
  span {
      font-family: Nunito;
      font-style: normal;
      font-weight: 600;
      line-height: 1.5;
      padding: 0px 2px;
      font-size: 1.4rem;

      &.last-class-at {
          border-radius: 9.5px;
          background: #f3e9ff;
          color: #a764ff;
          padding: 0px 5px;
      }

      &.last-class-at-activeUser {
          border-radius: 9.5px;
          background: rgba(58, 163, 204, 0.19);
          color: #3aa3cc;
          padding: 0px 5px;
      }

      &.class-at {
          border-radius: 9.5px;
          background: #f3e9ff;
          color: #a764ff;
          padding: 0px 5px;
      }

      &.class-at-activeUser {
          border-radius: 9.5px;
          background: rgba(58, 163, 204, 0.19);
          color: #3aa3cc;
          padding: 0px 5px;
      }
  }
    span + span[class] {
        margin-left: 5px;
    }
    span[class] + span[class] {
        margin-left: 5px;
    }
    span[class]:has(+ br) {
        margin-right: 5px;
    }
    span[class] + span {
        margin-left: 5px;
    }
`;

export const IconContainer = styled.div`
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  pointer-events: all;
  color: rgba(217, 217, 217, 1);

  svg {
    cursor: pointer;
    stroke: transparent;
    stroke-width: 0px;
    outline: none;
  }
`;

export const Line = styled.div`
  width: 1px;
  height: 20px;
  background: #c4c3c2;
`;

export const UserListGlobalContainer = styled.div<{ caretPosition: number, visibleRows: number }>`
  position: absolute;
  display: flex;
  width: 419px;
    max-height: 235px;
  padding: 15px 12px 15px 15px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  ${({ caretPosition }) => css`
    right: max(calc(650px - (8 * ${caretPosition}px)), 40px);
  `}
  ${({ visibleRows }) => css`
    top: calc(-60px - (41 * ${visibleRows}px));
  `}

  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #c4c3c2;
  background-color: white;

  span {
    flex-shrink: 0;
    align-self: stretch;
    color: rgba(61, 61, 61, 0.72);
    font-family: Nunito;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;

export const UserListSlider = styled.div`
  flex: 1;
  overflow-y: auto;
  min-height: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const UserListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 0;
  gap: 4px;
  align-self: stretch;
`;

export const UserRow = styled.div<{ selected?: boolean }>`
  display: flex;
  height: 25px;
  column-gap: 7px;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Nunito';
  font-size: 12px;
  width: 100%;
  font-weight: 500;
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;
  background-color: ${({ selected }) => (selected ? '#c8e3f64d' : 'transparent')};

  * {
    pointer-events: none;
  }

  .MuiAvatar-root {
    height: 20px;
    width: 20px;
    font-size: 10px;
  }
`;

export const UserItem = styled.div`
  flex: 1;
  display: flex;
  height: 25px;
  padding: 0px 10px 0px 5px;
  align-items: center;
`;
