import React from 'react';
import { BiMessageAdd } from 'react-icons/bi';
import { Avatar } from '@mui/material';
import { getUserNameById, stringAvatar } from 'src/utils/utils';
import {
  ChatBubble,
  ChatBubbleContainer,
  ChatBubbleRow,
  DateTimeContainer,
  Delete,
  EmptyChatContainer,
  TextContainer,
  TimeContainer,
} from './Chat.styles';
import dayjs from 'dayjs';
import { FaTimes } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { CommentProps } from '@components-new/common/types';

interface UserProps {
  id: string;
  name: string;
  firstName: string;
}

interface MessageListProps {
  isLoading: boolean;
  messages: CommentProps[];
  user: UserProps;
  deleteMessage: (messageID: string | number) => void;
  admin: boolean;
}

const MessageList: React.FC<MessageListProps> = ({ isLoading, messages, user, deleteMessage, admin }) => {
  const { t } = useTranslation();

  const isActiveUser = word => {
    return word === `@${user.firstName}` || word === `@${user.name}`;
  };
  const encodeStr = str => {
    if (!str) return;
    return str.replace(/(\@\S+)/g, key =>
      isActiveUser(key) ? `<span class="class-at-activeUser">${key}</span>` : `<span class="class-at">${key}</span>`
    );
  };

  let chatContent = (
    <div className='loading-messages-container'>
      <span className='loading-text'>{t('chat.message_list.loading_messages')}</span>
    </div>
  );

  const MessageComponent = ({ message, userDisabled }) => {
    return (
      <DateTimeContainer>
        {`${t('common.sent_at')} ${dayjs(message.time).format('DD/MM HH:mm')}`}
        {userDisabled ? <>{` - ${t('common.userNotExist')}`}</> : <>{` ${t('common.by')} ${message.sender.name}`}</>}
      </DateTimeContainer>
    );
  };

  if (!isLoading && !messages.length) {
    chatContent = (
      <EmptyChatContainer>
        <BiMessageAdd />
        <span
          dangerouslySetInnerHTML={{
            __html: t('chat.message_list.empty_chat_1'),
          }}></span>
        <span
          dangerouslySetInnerHTML={{
            __html: t('chat.message_list.empty_chat_2'),
          }}></span>
      </EmptyChatContainer>
    );
  } else if (!isLoading && messages.length) {
    chatContent = (
      <>
        {messages.map(message => {
          let isUser = user.id === message.sender.id;
          let renderName;
          if (isUser) {
            renderName = null;
          } else {
            renderName = <div className='sender-name'>{message.sender.name}</div>;
          }
          return (
            <ChatBubbleRow key={message._id} isUser={isUser}>
              <Avatar {...stringAvatar(getUserNameById(message.sender.id, admin)?.toLocaleUpperCase(), message.sender.id)} />
              <ChatBubbleContainer>
                <ChatBubble isUser={isUser} userDisabled={!getUserNameById(message.sender.id, admin)}>
                  {renderName}
                  <TextContainer
                    isUser={isUser}
                    userDisabled={!getUserNameById(message.sender.id, admin)}
                    dangerouslySetInnerHTML={{ __html: encodeStr(message.text) }}>
                  </TextContainer>
                </ChatBubble>
                <TimeContainer isUser={isUser}>
                  <MessageComponent message={message} userDisabled={!getUserNameById(message.sender.id, admin)} />
                </TimeContainer>
                {isUser && (
                  <Delete isUser={isUser} onClick={() => deleteMessage(message._id)}>
                    <FaTimes />
                  </Delete>
                )}
              </ChatBubbleContainer>
            </ChatBubbleRow>
          );
        })}
      </>
    );
  }
  return chatContent;
};

export default MessageList;
