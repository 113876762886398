import { AiOutlineFileExcel, AiOutlineFileImage, AiOutlineFilePdf, AiOutlineFileWord } from 'react-icons/ai';

import { COLORS } from '@utils/constants';

import { FileIconsPops } from './FileIcons.type';
import { IconContainer } from './FileIcons_.style';

const FileIcons = ({ filesType, onClick }: FileIconsPops) => {
  const fileTypesMapper = {
    xls: {
      icon: AiOutlineFileExcel,
      color: COLORS.xlsx,
    },
    xlsx: {
      icon: AiOutlineFileExcel,
      color: COLORS.xlsx,
    },
    pdf: {
      icon: AiOutlineFilePdf,
      color: COLORS.pdf,
    },
    doc: {
      icon: AiOutlineFileWord,
      color: COLORS.docx,
    },
    docx: {
      icon: AiOutlineFileWord,
      color: COLORS.docx,
    },
  };

  const imageFileTypes = ['png', 'jpeg', 'jpg', 'jfif'];
  imageFileTypes.forEach(type => {
    fileTypesMapper[type] = {
      icon: AiOutlineFileImage,
      color: COLORS.Green,
    };
  });

  return (
    <IconContainer onClick={onClick}>
      {filesType.map((fileType, index) => {
        const { icon: IconComponent, color } = fileTypesMapper?.[fileType.toLowerCase()];
        return <IconComponent key={index} size={32} color={color} />;
      })}
    </IconContainer>
  );
};

export default FileIcons;
