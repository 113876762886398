import styled, { css } from 'styled-components';

import { IoChatbubblesOutline } from 'react-icons/io5';

import { AvatarGroup, Stack, Select as MUISelect } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { InputComponent } from '@components/form/InputComponent';

import { COLORS } from 'src/utils/constants';

export const AvatarContainer = styled(AvatarGroup)`
  justify-content: flex-end;
`;

export const CustomStack = styled(Stack)`
  font-size: 2rem;
  padding-left: 15%;
`;

// ------------- Modal Subcontractor -----------------
export const BasicModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  column-gap: 10px;
  row-gap: 20px;

  b {
    font-size: 0.8em;
    font-weight: 400;
    font-family: 'Roboto', serif;
    line-height: 1em;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  column-gap: 20px;
  align-items: center;
  justify-content: center;
`;

export const Infos = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;

  svg {
    color: ${COLORS.Squash};
    animation: swing 0.8s infinite ease-in-out;
  }
`;

export const ChatBubbleIcon = styled(IoChatbubblesOutline) <{ active: boolean }>`
  ${({ active }) =>
    !active &&
    `
     opacity: 0.5;
`}
`;

// ------------------ Action Details styles ---------------------

export const DivContainer = styled.div<{ center?: boolean; editable?: boolean; size?: string; height?: boolean; bolder?: boolean }>`
  display: grid;
  height: ${({ height }) => (height ? '4rem' : 'unset')};
  align-items: center;
  grid-template-columns: 15% 84%;
  column-gap: 1%;
  margin-bottom: 0.75rem;
  margin-top: 0.75rem;
  align-items: ${({ center }) => (center ? 'center' : 'unset')};
  font-weight: ${({ bolder }) => (bolder ? 'bold' : 'unset')};
  color: grey;
  span[datatype='label'] {
    color: black;
    font-weight: bold;
  }
  .justify-text {
    text-align: justify;
  }

  ${({ size }) => {
    switch (size) {
      case 'small':
        return css`
          font-size: 1.2rem;
        `;
      default:
        return null;
    }
  }}
`;
export const Tag = styled.div<{ status?: string; urgency?: string }>`
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 10px;
  width: fit-content;
  svg {
    margin-right: 1rem;
  }

  ${({ status }) => {
    switch (status) {
      case 'pending':
        return `
          color:#7f7f7f;
          // background-color: #dedede85;
          // border: 0.5px solid #7f7f7f;
        `;
      case 'ongoing':
        return `
          color:#ff8157;
          // background-color: #ffaa3754;
          // border: 0.5px solid #ff8157;
        `;
      case 'completed':
        return `
          color:#28a745;
          // background-color: #87d37c30;
          // border: 0.5px solid #87d37c;
        `;
      default:
        break;
    }
  }}

  ${({ urgency }) => {
    switch (urgency) {
      case 'low':
        return `color:#0091ff;`;
      case 'medium':
        return `color:#d4af37;`;
      case 'high':
        return `color:#ef3e36;`;
      default:
        break;
    }
  }}
`;

export const CustomSelect = styled(MUISelect)`
  width: 100%;
  max-width: 15%;
  font-size: 1.2rem !important;
  padding: 0;
  input,
  fieldset {
    border: none;
  }
  .MuiSelect-select {
    padding-left: 0;
    color: grey;
  }
  .MuiPickersPopper-root {
    z-index: 9999;
  }
`;

export const CustomDatePicker = styled(DatePicker) <{ editable: boolean }>`
  input,
  fieldset {
    border: none;
  }
  input {
    padding-left: 2rem;
    padding-top: 0;
    padding-bottom: 0;
    color: grey;
  }
  .MuiInputBase-root {
    flex-direction: row-reverse;
    font-size: ${({ editable }) => editable && '1.2rem !important'};
  }
  .MuiIconButton-root {
    padding: 0;
  }
  .MuiInputAdornment-root {
    margin-left: 0;
  }
`;

export const ResponsibleContainer = styled.div`
  display: grid;
  & > :not(:last-child) {
    margin-bottom: 1rem;
  }
  width: 100%;
`;

export const ResponsibleItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  & > :not(:last-child) {
    margin-right: 1rem;
  }
`;

export const TabsContainer = styled.div`
  width: 100%;
  font-weight: bold;
  display: flex;
`;

export const Tab = styled.div<{ selected: boolean }>`
  padding: 20px 15px;
  height: calc(100% - 40px);
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.2s ease;
  cursor: pointer;

  ${({ selected }) =>
    selected &&
    `
      background-color: #326C82;
      color: #fff;     
  `}
  &:first-child {
    border-top-left-radius: 4px;
    border-right: 1px solid ${COLORS.MediumGrey};
  }
  &:last-child {
    border-top-right-radius: 4px;
  }
  &:hover {
    background-color: #326c82;
    color: #fff;
  }
`;

export const Badge = styled.div<{ variant?: string }>`
  width: fit-content;
  height: fit-content;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 0.875rem;
  border-radius: 0.625rem;
  font-weight: 700;
  transition: 0.3s all;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  padding: 0.75rem;
  margin-right: 1rem;
  ${({ variant }) => {
    return variant === 'button' && 'cursor : pointer;';
  }};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const InputComponentContainer = styled(InputComponent)``;

export const Link = styled.a`
  display: block;
  word-wrap: break-word;
  width: 25%;
`;

export const ProofFilesContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  & > :not(:last-child) {
    margin-right: 1rem;
  }
  svg {
    margin-left: 0;
  }
`;
