import { Modal } from '../modal/Modal';
import {
  ButtonContainer,
  GlobalContainer,
  DescriptionContainer,
  DescriptionHeader,
  DescriptionContent,
  Previous,
  StepsContainer,
  Step,
  StepLabel,
  StyledMdArrowForwardIos,
  EmptyStep,
  EmptyEntity,
} from './ModalUpdateSurveyRefs_.style';
import { Button } from '@components/Button';
import { InputComponent } from '@components/form/InputComponent';
import { useState } from 'react';
import { PiBriefcaseThin } from 'react-icons/pi';

export interface ModalRevertMigrationProps {
  show?: boolean;
  onClose: () => void;
  onUpdateSurveyRefs: (oldSurveyId: string, newSurveyId: string, oldSurveyVersionId: string, newSurveyVersionId: string, newSurveyVersionName: string) => void;
  contentfulSurveys: any;
  clientEntities: any;
}

export const ModalUpdateSurveyRefs = ({
                                       show,
                                       onClose,
                                        onUpdateSurveyRefs,
                                       contentfulSurveys,
                                        clientEntities
                                     }: ModalRevertMigrationProps) => {
  const [open, setOpen] = useState(true);
  const [currentStep, setCurrentStep] = useState(1);
  const [oldSurvey, setOldSurvey] = useState(null);
  const [oldSurveyVersion, setOldSurveyVersion] = useState(null);
  const [newSurvey, setNewSurvey] = useState(null);
  const [newSurveyVersion, setNewSurveyVersion] = useState(null);

  const getSurveysOptions = () => {
    return contentfulSurveys.map(survey => ({ value: survey.id, label: survey.name + ` / (${survey.id})` })) || [];
  };

  const getSurveyVersionsOptions = (targetSurvey) => {
    let filteredSurveys = clientEntities.flatMap(entity => (entity.responses.filter( survey => (survey.surveyId === targetSurvey.id && survey.versions.length))));
    return filteredSurveys.flatMap(survey => survey.versions.map(surveyVersion => ({ value: surveyVersion._id, label: surveyVersion.versionName}))) || [];
  };

  const handlePreviousButton = () => {
    if (currentStep === 4) {
      setOldSurvey(null);
      setNewSurvey(null);
    }
    if (currentStep === 5) {
      setNewSurvey(null);
    }
    setCurrentStep(prevState => prevState - 1);
  };

  const handleOldSurvey = (e, surveyId) => {
    setCurrentStep(2);
    let fullSurvey = contentfulSurveys.find(survey => survey.id === surveyId);
    setOldSurvey({ id: fullSurvey.id ?? surveyId, name: fullSurvey.name ?? '' });
  };

  const handleOldSurveyVersion = (e, surveyVersionId) => {
    setCurrentStep(3);
    let surveyVersion = getSurveyVersionsOptions(oldSurvey).find(value => (value.value === surveyVersionId));
    setOldSurveyVersion({ id: surveyVersionId, name: surveyVersion.label });
  };

  const handleNewSurvey = (e, surveyId) => {
    setCurrentStep(4);
    let fullSurvey = contentfulSurveys.find(survey => survey.id === surveyId);
    setNewSurvey({ id: fullSurvey.id ?? surveyId, name: fullSurvey.name ?? '' });
  };

  const handleNewSurveyVersion = (e, surveyVersionId) => {
    let surveyVersion = getSurveyVersionsOptions(newSurvey).find(value => (value.value === surveyVersionId));
    setNewSurveyVersion({ id: surveyVersionId, name: surveyVersion.label });
  };

  const handleUpdateSurveyRefs = () => {
    if(currentStep === 5) {
      onClose();
    }
    if (currentStep === 4) {
      onUpdateSurveyRefs(oldSurvey?.id, newSurvey?.id, oldSurveyVersion?.id, newSurveyVersion?.id, newSurveyVersion?.name);
    }
    setCurrentStep(prevState => prevState + 1);
  };
  const getButtonLabel = () => {
    switch (currentStep) {
      case 1:
      case 2:
      case 3:
        return 'SUIVANT';
      case 4:
        return 'METTRE A JOUR LES REFS';
      case 5:
        return "J'AI COMPRIS";
    }
  };

  const isButtonDisabled = !oldSurvey?.id || !newSurvey?.id
  return (
    <>
      {show && (
        <Modal onClose={onClose} size={{ height: '75%', width: '40%' }} show={show} title={'Espace mise à jour des liens de questionnaires :'}>
          <GlobalContainer>
            {currentStep < 5 && (
              <DescriptionContainer>
                <DescriptionHeader onClick={() => setOpen(prevState => !prevState)}>
                  <b>Comment ça marche ?</b>
                  <StyledMdArrowForwardIos open={open} className={'arrow'} />
                </DescriptionHeader>
                <DescriptionContent open={open}>
                  {open && (
                    <>
                      <div>1. Veuillez sélectionner le questionnaire qui n'a plus de référence (ancien questionaire)</div>
                      <div>2. Sélectionnez ensuite le questionnaire référence (nouveau questionnaire)</div>
                      <div>3. Mettez à jour les liens (mise à jour des liens pour les actions + éléments partagés)</div>
                      <div>(n.b : La mise à jour des liens impactera l'ensemble des entités du compte)</div>
                    </>
                  )}
                </DescriptionContent>
              </DescriptionContainer>
            )}
            <StepsContainer>
              {currentStep < 5 && (
                <Step>
                  <StepLabel>
                    <b>Etape 1/5 :</b> Sélection de l'ancien questionnaire (qui n'a plus de référence) :
                  </StepLabel>
                  <InputComponent
                    type={'select'}
                    options={getSurveysOptions()}
                    onChange={handleOldSurvey}
                    sharable={false}
                    value={getSurveysOptions().filter(value => {
                      return value.value === oldSurvey?.id;
                    })}
                  />
                </Step>
              )}
              {currentStep < 5 && oldSurvey && (
                <Step>
                  <StepLabel>
                    <b>Etape 2/5 :</b> Sélection de l'ancienne version (qui n'a plus de référence) :
                  </StepLabel>
                  {oldSurvey ? (
                    <InputComponent
                      type={'select'}
                      options={getSurveyVersionsOptions(oldSurvey)}
                      onChange={handleOldSurveyVersion}
                      sharable={false}
                      value={getSurveyVersionsOptions(oldSurvey).filter(value => {
                        return value.value === oldSurveyVersion?.id;
                      })}
                    />
                  ) : (
                    <EmptyEntity>
                      <EmptyStep>
                        <PiBriefcaseThin />
                        <span>Veuillez sélectionner un questionnaire qui n'a plus de référence</span>
                      </EmptyStep>
                    </EmptyEntity>
                  )}
                </Step>
              )}
              {currentStep < 5 && oldSurveyVersion && (
                <Step>
                  <StepLabel>
                    <b>Etape 3/5 :</b> Sélection du nouveau questionnaire (questionnaire référence) :
                  </StepLabel>
                  {oldSurvey ? (
                    <InputComponent
                      type={'select'}
                      options={getSurveysOptions()}
                      onChange={handleNewSurvey}
                      sharable={false}
                      value={getSurveysOptions().filter(value => {
                        return value.value === newSurvey?.id;
                      })}
                    />
                  ) : (
                    <EmptyEntity>
                      <EmptyStep>
                        <PiBriefcaseThin />
                        <span>Veuillez sélectionner un questionnaire qui n'a plus de référence</span>
                      </EmptyStep>
                    </EmptyEntity>
                  )}
                </Step>
              )}
              {currentStep < 5 && newSurvey && (
                <Step>
                  <StepLabel>
                    <b>Etape 4/5 :</b> Sélection de la nouvelle version (version référence) :
                  </StepLabel>
                  {newSurvey ? (
                    <InputComponent
                      type={'select'}
                      options={getSurveyVersionsOptions(newSurvey)}
                      onChange={handleNewSurveyVersion}
                      sharable={false}
                      value={getSurveyVersionsOptions(newSurvey).filter(value => {
                        return value.value === newSurveyVersion?.id;
                      })}
                    />
                  ) : (
                    <EmptyEntity>
                      <EmptyStep>
                        <PiBriefcaseThin />
                        <span>Veuillez sélectionner un questionnaire qui n'a plus de référence</span>
                      </EmptyStep>
                    </EmptyEntity>
                  )}
                </Step>
              )}
              {currentStep === 5 && (
                <Step>
                  <StepLabel>
                    <b>Etape 5/5 :</b>  Changement de questionnaire référence effectué  !
                  </StepLabel>
                  <p>La màj des liens du questionnaire a été réalisée avec succès !</p>
                  <p>
                    Votre questionnaire "{oldSurvey.name}" a bien été remplacé dans l'ensemble des entités."
                  </p>
                  <p>
                    Le questionnaire "{newSurvey.name}" est désormais le nouveau questionnaire référence.
                  </p>
                </Step>
              )}
            </StepsContainer>
            <ButtonContainer>
              {currentStep > 1 && <Previous onClick={handlePreviousButton}>{'PRÉCÉDENT'}</Previous>}
              <Button onClick={handleUpdateSurveyRefs} label={getButtonLabel()} disabled={isButtonDisabled} customColor={'rgba(244, 157, 29, 1)'} />
            </ButtonContainer>
          </GlobalContainer>
        </Modal>
      )}
    </>
  );
};
