import { COLORS } from '../../utils/constants';
import styled, { css } from 'styled-components';

const FileSlider = styled.div`
  height: 100%;
  overflow-y: auto;
`;

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.5);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const FileExplorerContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  row-gap: 20px;
  position: relative;
`;

const Content = styled.div`
  position: relative;
  flex: 1;

  > * {
    &:focus-visible {
      outline: none;
    }
  }
`;

const Empty = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  row-gap: 20px;
  border: 1px dashed ${COLORS.MediumGrey};

  span {
    font-size: 1.3rem;
    width: 500px;
    max-width: 90%;
    text-align: center;

    b {
      color: ${COLORS.NiceBlue};
    }
  }

  svg {
    color: ${COLORS.MediumGrey};
    width: 60px;
    height: 60px;
    animation: swing 0.8s ease 0.3s;
  }
`;

const BasicModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  column-gap: 20px;
  align-items: center;
  row-gap: 26px;
  width: 100%;

  p {
    font-size: 1.3em;
    font-weight: 400;
    font-family: 'Roboto', serif;
    line-height: 1.5em;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  column-gap: 20px;
  align-items: center;
`;

const DragOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f595;
  border: 1px dashed #e0e0e0;
  z-index: 99999;
  font-size: 1.5em;
  color: #9e9e9e;
`;

const Link = styled.a``;

const ExplorerItem = styled.div<{ type?: string; selected?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  width: 100px;
  position: relative;
  ${({ selected }) =>
    selected &&
    css`
      border: 2px dashed ${COLORS.MediumGrey};
      border-radius: 10px;
      padding: 10px;
    `};

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 10px;
    position: absolute;
    right: -25px;
    top: 50px;
    height: 100px;
    transition: all 0.2s ease-in-out;

    > svg,
    > a > svg {
      height: 25px;
      width: 25px;
      opacity: 0;
      color: ${COLORS.Grey};
      transition: opacity 0.2s ease-in-out;

      &:hover {
        color: ${COLORS.Black};
      }
    }
  }

  > svg {
    height: 100px;
    width: 100px;
    opacity: 0.8;
    transition: opacity 0.2s ease-in-out;

    ${({ type }) =>
      type &&
      css`
        color: ${COLORS[type]};
      `}
  }

  span {
    text-align: center;
    font-size: 1.225rem;
    font-weight: 500;
    color: ${COLORS.Grey};
  }

  &:hover {
    > div {
      top: 0;

      > svg,
      > a > svg {
        opacity: 0.8;

        &:hover {
          opacity: 1;
        }
      }
    }

    > svg {
      opacity: 1;
    }
  }
`;

const FileExplorerWindow = styled.div`
  width: calc(100% - 30px);
  height: calc(100% - 30px);
  padding: 15px;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  position: relative;
`;

const FileContainer = styled.div`
  display: grid;
  row-gap: 15px;
  column-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  justify-items: center;
  height: 0;
`;

const BreadcrumbContainer = styled.div`
  display: flex;
  column-gap: 10px;
  align-items: center;
`;

const BreadcrumbItem = styled.div<{ current: boolean; disableBreadcrumb: boolean }>`
  font-size: 1.125rem;
  color: ${COLORS.Grey};
  font-family: 'Roboto', sans-serif;
  opacity: 0.8;
  // cursor: pointer;
  transition: opacity 0.2s ease-in-out;

  ${({ current }) =>
    current &&
    css`
      font-weight: bold;
      color: ${COLORS.NiceBlue} !important;
    `}
  ${({ disableBreadcrumb }) =>
    !disableBreadcrumb &&
    css`
      cursor: pointer;
      &:hover {
        opacity: 1;
      }
    `}
`;

export {
  FileSlider,
  ActionContainer,
  LoaderContainer,
  FileExplorerContainer,
  Content,
  Empty,
  BasicModalContainer,
  ButtonContainer,
  DragOverlay,
  Link,
  ExplorerItem,
  FileExplorerWindow,
  FileContainer,
  BreadcrumbContainer,
  BreadcrumbItem,
};
